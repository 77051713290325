import React from "react"
import { Helmet } from "react-helmet"
import { Router } from "@reach/router"
import { SkoleniDetail } from "../components/goods/SkoleniDetail"
import { Skoleni } from "../components/goods/Skoleni"

const SkoleniPage = ({ location }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Školení</title>
      </Helmet>

      <Router>
        <SkoleniDetail path="/kurz/:alias" />
      </Router>
    </>
  )
}

export default SkoleniPage
